import constants from "rtr-constants";
import ActionTypes from "../actions/action-types";

const INITIALIZED_SURVEY_STATUS = "INITIALIZED";

const happinessSurveyModification = (state, action) => {
  const happinessSurvey = state?.happinessSurvey ? { ...state.happinessSurvey } : {};

  switch (action.type) {
    case ActionTypes.FETCH_HAPPINESS_SURVEYS_SUCCESS: {
      const itemSurveys = action.payload.map((survey, index) => ({
        ...survey,
        inProgress: index === 0, // Set the first survey to inProgress
      }));
      return { ...happinessSurvey, itemSurveys };
    }

    case ActionTypes.FETCH_HAPPINESS_SURVEYS_COMPLETED_SUCCESS:
      return { ...happinessSurvey, completedSurveys: action.payload };

    case ActionTypes.HAPPINESS_SURVEY_ADD_FEEDBACK: {
      const { bookingId, feedback, exclusiveFeedbackTypeOption, feedbackValuesMap } = action.payload;
      const updatedItemSurveys = happinessSurvey.itemSurveys.map(survey => {
        if (survey.bookingId !== bookingId) return survey;

        const feedbackValues = feedbackValuesMap ?? constants.happinessSurveyValues[exclusiveFeedbackTypeOption];
        const filteredFeedbacks =
          survey.surveyFeedbacks?.filter(f => !Object.keys(feedbackValues || {}).includes(f.feedback)) || [];

        return {
          ...survey,
          surveyFeedbacks: feedback ? [...filteredFeedbacks, { bookingId, feedback }] : filteredFeedbacks,
        };
      });
      return { ...happinessSurvey, itemSurveys: updatedItemSurveys };
    }

    case ActionTypes.HAPPINESS_SURVEY_COMPLETED: {
      const { bookingId } = action.payload;
      const updatedItemSurveys = happinessSurvey.itemSurveys.map(survey => {
        if (survey.bookingId === bookingId) {
          return { ...survey, status: constants.happinessSurveyValues.status.COMPLETE, inProgress: false };
        }
        if (survey.status === INITIALIZED_SURVEY_STATUS && !survey.inProgress) {
          return { ...survey, inProgress: true };
        }
        return survey;
      });
      return { ...happinessSurvey, itemSurveys: updatedItemSurveys };
    }

    case ActionTypes.HAPPINESS_SURVEY_ADD_COMMENT: {
      const { bookingId, comment } = action.payload;
      const updatedItemSurveys = happinessSurvey.itemSurveys.map(survey =>
        survey.bookingId === bookingId ? { ...survey, comment } : survey
      );
      return { ...happinessSurvey, itemSurveys: updatedItemSurveys };
    }

    case ActionTypes.HAPPINESS_SURVEY_ADD_RATING: {
      const { bookingId, rating } = action.payload;
      const updatedItemSurveys = happinessSurvey.itemSurveys.map(survey =>
        survey.bookingId === bookingId ? { ...survey, rating } : survey
      );
      return { ...happinessSurvey, itemSurveys: updatedItemSurveys };
    }

    case ActionTypes.HAPPINESS_SURVEY_ADD_CAPTION: {
      const { bookingId, caption } = action.payload;
      const updatedItemSurveys = happinessSurvey.itemSurveys.map(survey =>
        survey.bookingId === bookingId ? { ...survey, caption } : survey
      );
      return { ...happinessSurvey, itemSurveys: updatedItemSurveys };
    }

    case ActionTypes.HAPPINESS_SURVEY_ADD_REVIEW_CONTENT: {
      const { bookingId, reviewContent } = action.payload;
      const updatedItemSurveys = happinessSurvey.itemSurveys.map(survey =>
        survey.bookingId === bookingId ? { ...survey, reviewContent } : survey
      );
      return { ...happinessSurvey, itemSurveys: updatedItemSurveys };
    }

    case ActionTypes.SET_HAPPINESS_SURVEY_INFO:
      return {
        ...happinessSurvey,
        type: action.payload.type,
        reserveGroupId: action.payload.reserveGroupId,
      };

    case ActionTypes.HAPPINESS_SURVEY_REMOVE_FEEDBACK: {
      const { bookingId, feedback } = action.payload;
      const updatedItemSurveys = happinessSurvey.itemSurveys.map(survey =>
        survey.bookingId === bookingId
          ? { ...survey, surveyFeedbacks: survey.surveyFeedbacks?.filter(f => f.feedback !== feedback) }
          : survey
      );
      return { ...happinessSurvey, itemSurveys: updatedItemSurveys };
    }

    case ActionTypes.HAPPINESS_SURVEY_REMOVE_ALL_FEEDBACK: {
      const bookingId = action.payload;
      const updatedItemSurveys = happinessSurvey.itemSurveys.map(survey =>
        survey.bookingId === bookingId ? { ...survey, surveyFeedbacks: [] } : survey
      );
      return { ...happinessSurvey, itemSurveys: updatedItemSurveys };
    }

    case ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_SUCCESS:
      return {
        ...happinessSurvey,
        completedSurveysByStyle: action.payload,
        completedSurveysByStyleLoading: false,
      };

    case ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_BEGIN:
      return {
        ...happinessSurvey,
        completedSurveysByStyle: null,
        completedSurveysByStyleLoading: true,
      };

    case ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_FAILURE:
      return {
        ...happinessSurvey,
        completedSurveysByStyle: null,
        completedSurveysByStyleLoading: false,
      };

    case ActionTypes.HAPPINESS_SURVEY_SET_ITEM_IN_PROGRESS: {
      const bookingId = action.payload;
      const updatedItemSurveys = happinessSurvey.itemSurveys.map(survey => ({
        ...survey,
        inProgress: survey.bookingId === bookingId,
      }));

      // If the bookingId is not found, set the first item to inProgress
      if (!updatedItemSurveys.some(survey => survey.bookingId === bookingId)) {
        return {
          ...happinessSurvey,
          itemSurveys: updatedItemSurveys.map((survey, index) => ({
            ...survey,
            inProgress: index === 0,
          })),
        };
      }

      return { ...happinessSurvey, itemSurveys: updatedItemSurveys };
    }

    default:
      return happinessSurvey;
  }
};

const types = [
  ActionTypes.FETCH_HAPPINESS_SURVEYS_SUCCESS,
  ActionTypes.FETCH_HAPPINESS_SURVEYS_COMPLETED_SUCCESS,
  ActionTypes.HAPPINESS_SURVEY_ADD_FEEDBACK,
  ActionTypes.HAPPINESS_SURVEY_REMOVE_FEEDBACK,
  ActionTypes.HAPPINESS_SURVEY_REMOVE_ALL_FEEDBACK,
  ActionTypes.HAPPINESS_SURVEY_ADD_COMMENT,
  ActionTypes.HAPPINESS_SURVEY_ADD_RATING,
  ActionTypes.HAPPINESS_SURVEY_ADD_CAPTION,
  ActionTypes.HAPPINESS_SURVEY_ADD_REVIEW_CONTENT,
  ActionTypes.HAPPINESS_SURVEY_COMPLETED,
  ActionTypes.HAPPINESS_SURVEY_SET_ITEM_IN_PROGRESS,
  ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_BEGIN,
  ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_SUCCESS,
  ActionTypes.HAPPINESS_SURVEYS_BY_STYLE_FAILURE,
  ActionTypes.SET_HAPPINESS_SURVEY_INFO,
];

const fn = (state, action) => ({
  ...state,
  happinessSurvey: happinessSurveyModification(state, action),
});

export default {
  types,
  fn,
};
