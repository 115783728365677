// These are flags retrievable through Godmother/Launch Darkly, a separate implementation from RTR internal feature flags/Alchemy experiments
// quick feature flag naming guide: `${Team}-{Where It’s Used}-${Jira Ticket}-${Short Description}`
// detailed explanation here: https://renttherunway.jira.com/wiki/x/D4B35

export const flagNames = {
  AI_SEARCH_V_1_STOREFRONT_UX: "ai-search-v-1-storefront-ux",
  CE_KIFAH_CHECKOUT_IN_BAG: "ce-kifah-checkout-in-bag",
  CE_GIFT_CARDS: "ce-gift-cards",
  CE_GIFT_CARD_PINS: "ce-gift-card-pins",
  CE_2982_GIFT_CARD_UNLOAD_CLIENT_UI_FEATURE_TOGGLE:
    "commerce-services-ce-2982-gift-card-unload-client-ui-feature-toggle",
  COMET_APPLE_PAY_KIFAH: "storefront-apple-pay-kifah",
  COMET_APPLE_PAY_SUBSCRIPTION: "storefront-apple-pay-poc",
  COMET_COMMERCE_PAYMENT_METHOD_ASSIGNMENT: "commerce-enable-payment-method-assignment",
  COMET_SF_ENABLE_RECURRING_BILLING: "storefront-enable-recurring-payment-method-management",
  CONVERGE_FACEBOOK_ENABLED: "martech-converge-pc-24-converge-client-side-product-page-tracking-feature-toggle",
  DISCO_SEARCH_AI_MVP_STOREFRONT_UX: "disco-search-ai-mvp-storefront-ux",
  DRE_ONBOARDING_STYLE_PREFERENCES: "dre-sf-dr-909-910-onboarding-style-preferences", // to be removed with DR-917
  DRE_SF_DR_786_ENABLE_MULTI_ORDER_SELECT_IN_STANDALONE_SCHEDULER:
    "dre-sf-dr-786-enable-multi-order-select-in-standalone-scheduler",
  DRE_SF_DR_824_OUT_OF_SHIPMENTS_SCREEN: "dre-sf-dr-824-out-of-shipments-screen", // to be removed with https://renttherunway.jira.com/browse/SWAT-633
  DRE_SF_DR_946_SIMPLIFY_MEMBERSHIP_BAG_ENABLED: "dre-sf-dr-946-simplify-membership-bag-enabled", // to be removed with https://renttherunway.jira.com/browse/SWAT-633
  DRE_SF_ONBOARDING_LANDING_PAGE: "dre-sf-onboarding-landing-page", // to be removed in DR-874
  ENABLE_HOME_PICKUP_NEW_TAG: "enable-hpu-new-tag",
  ENABLE_REWORKED_HOME_PICKUP: "enable-reworked-hpu-scheduler",
  GA4_CART_ENABLED: "martech-sf-martech-135-enable-cart-GA4",
  GA4_CHECKOUT_ENABLED: "martech-sf-martech-202-enable-ga-4",
  GA4_PURCHASE_ENABLED: "martech-sf-martech-208-enable-purchase-GA4",
  GA4_USE_PROMO_ENABLED: "martech-sf-martech-138-use-promo-GA4",
  GA4_VIEW_ITEM_ENABLED: "martech-sf-martech-134-view-item-GA4",
  HIDE_BROWSE_ALL_FROM_T0_SUBS: "lgtm-cms-ma-2663-hide-browse-all-from-t-0-subscribers",
  MARTECH_ENABLE_EMAIL_POPUP: "martech-storefront-martech-300-enable-email-model-popup",
  NEW_GRID_PDP_DRAWER: "reserve-storefront-rsv-779-new-grid-product-drawer-feature-toggle",
  PDP_MEMBERSHIP_UPSELL_5_ITEMS: "lgtm-sf-ma-2167-5-item-pdp-toggle",
  SF_ROKT: "sf-rokt",
  SACT_39_SIZE_LOCATION_FILTER_FOR_SUB_LENS: "sact-sf-sact-39-size-location-filter-for-sub-lens",
  SACT_98_AVAILABLE_NOW_ITEMS_IN_SUB_LENS: "sact-sf-sact-98-available-now-items-in-sub-lens",
  SWAT_840_CUSTOMER_PROMISE: "swat-swat-840-customer-promise",
  SACT_DISCO_ENDPOINTS_CAROUSELS: "sact-sf-sact-69-disco-endpoints-for-membership-carousels",
  SF_CE_DELAYED_ACCOUNT_CREATION: "sf-ce-delayed-account-creation",
  SF_MEMBERSHIP_4_TO_5_ITEM_TRANSITION_GTM: "sf-membership-4-to-5-item-transition-gtm",
  SF_SACT_250_NOTIFY_ME: "sf-sact-250-notify-me",
  SHOW_MY_RTR_FILTERS_CLEARANCE: "tux-storefront-next-tux-829-clearance-grids-my-rtr-filters-unlock", // to be remove in TUX-844
  SHOW_MY_RTR_FILTERS_DESIGNER_CATEGORY_CURATION:
    "tux-storefront-next-tux-843-designer-category-curation-grids-my-rtr-filters-unlock", // to be removed in TUX-845
  SMS_OPT_IN_AT_STANDARD_CHECKOUT: "sms-opt-in-at-standard-checkout",
  SWAT_230_MEMBERSHIP_HOMEPAGE_GUIDED_TOUR: "swat-230-membership-homepage-guided-tour",
  SWAT_CONCIERGE_FOOTER: "swat-storefront-swat-141-concierge-footer",
  SWAT_CONCIERGE_UNAVAILABLE_PDPS: "swat-sf-dr-1022-concierge-unavailable-pdp-placement",
  SWAT_PAUSE_OFFER_FLAG: "swat-storefront-dr-1034-pause-offer",
  SWAT_T0_PICK_FOR_ME_LINK_FLAG: "swat-storefront-dr-1033-t-0-pick-for-me-link",
  SWAT_SF_SWAT_650_HAPPINESS_SURVEY_SKIP: "swat-sf-swat-650-happiness-survey-skip",
  SWAT_STOREFRONT_CLEARANCE_GRID_DEFAULT_SORT: "swat-storefront-swat-787-clearance-grid-default-sort-segmentation", // removal ticket: SWAT-843
  TUX_622_CAROUSELS_UNLOCK: "tux-storefront-tux-622-unlock-carousel-scroll-on-mweb-feature-toggle",
  USE_HOME_PICKUP_POSTAL_CODE_GROUPS: "dre-sf-use-home-pickup-postal-code-groups",
  RESERVE_913_PROBLEM_ORDER_FLOW: "reserve-storefront-rsv-913-PO-order-flow-feature-flag",
  RSV_1326_DISABLE_OLD_DRAWER: "rsv-1326-disable-old-drawer-on-homepage-and-grids",
  CATALOG_SERVIES_STOREFRONT_ENABLE_TYPEAHEAD: "catalog-services-storefront-enable-typeahead",
  CONCO_STOREFRONT_CONCO_1995_REMOVE_SIGNIN_DISABLE_BUTTON_KILL_SWITCH:
    "conco-storefront-conco-1995-remove-signin-disable-button-kill-switch",
};

// These are experiments retrievable through Godmother/Launch Darkly, a separate implementation from RTR internal Alchemy experiments
// these values need to be fetched serverside/before heap is initialized to attach as key/values to heap user/event props
// the flag names will be the experiment key names and their flag values will dictate the if they are the control vs treatment variables
// right now, every experiment listed here will be tracked, ticket to investigate a better method here: https://renttherunway.jira.com/browse/SWAT-615
export const experimentNames = {
  SWAT_SF_SWAT_624_THIRTY_DAY_KIFAH_TRIGGER: "swat-sf-swat-624-thirty-day-kifah-trigger",
  RSV_KIFFR_BUTTON_ON_RESERVE_PDP: "rsv-943-kiffr-button-on-reserve-pdp",
  RSV_UPSELL_MODAL: "rsv-1114-upsell-modal", // ticket to remove: https://renttherunway.jira.com/browse/RSV-1197
  RSV_CHANGE_DEFAULT_IMAGE_IN_BAG: "rsv-1195-change-default-image-in-add-to-bag", // ticket to remove: https://renttherunway.jira.com/browse/RSV-1204
  RSV_1198_NCO_TOP_RIGHT_CHANGE_TEXT_FOR_RESERVE_USERS: "rsv-1198-nco-top-right-change-text-for-reserve-users",
  RSV_1372_VERIFY_LD_EXP_USING_HEAP: "rsv-1372-verify-ld-experiments-using-heap", //ticekt to remove: https://renttherunway.jira.com/browse/RSV-1424
  RSV_1421_URGENCY_SIGNAL: "rsv-1421-urgency-signal", //ticekt to remove: https://renttherunway.jira.com/browse/RSV-1423
};

export default {
  ...flagNames,
  ...experimentNames,
};
