import { buildPurchaseEvents } from "./heap-invoice-helpers";
import { getUserId, isIdentified } from "components/source/hoc/with-user-data";
import { isActiveMembership, isSubscriptionLens, isClassicLens } from "helpers/membership-helpers";
import { isCanceled } from "helpers/membership-plan-helpers";
import { isStorefrontNext } from "helpers/environment-helpers";
import { parseISOWithoutTime } from "./date-helpers";
import { differenceInDays } from "date-fns";
import { COOKIES, membershipSlotTypes, membershipLens } from "rtr-constants";
import { isSSR } from "./client-server-helper";
import { experimentNames as launchDarklyExperiments } from "constants/flags-and-experiments-names";
import Cookies from "universal-cookie";
const cookies = new Cookies();
import UAParser from "ua-parser-js";
import carouselTypes from "./carousel-types";
import { getLocationPathname } from "./location-helpers";

const pixelServiceProperties = () => {
  return {
    rtr_id: cookies.get("RTR_ID"),
    rtr_sess: cookies.get("RTR_SESS"),
  };
};

const invokeHeapFunctionWithTryCatch = fn => {
  if (isSSR()) {
    return;
  }
  try {
    if (window?.heap) {
      fn();
    } else {
      // not present now, might be later
      window?.addEventListener("heapLoaded", fn, { once: true });
    }
  } catch (error) {
    window.Sentry?.captureException(error, {
      tags: {
        thirdparty: true,
        thirdparty_name: "heap",
      },
    });
  }
};

/**
 * data-heap-id attributes for add to bag buttons.
 */
export const HEAP_ADD_TO_BAG_IDS = {
  CLEARANCE: "clearance_atb",
  KIFAH: "kifah_atb",
  KIFFR: "kiffr_atb",
  MEMBERSHIP_ITEM: "membership_item_atb",
  RESERVE: "reserve_atb",
  SALEABLE: "saleable_atb",
  SUBSCRIPTION_PURCHASE: "subscription_purchase_atb",
};

export const HEAP_AUTH_TRIGGER_TYPES = {
  /**
   * Identified user is prompted to authorize
   */
  AUTH_PROMPT: "auth_prompt",
  /**
   * Auth modal popped automatically on page load
   */
  AUTO_TRIGGER: "auto_trigger",
  CLICK_ANON_HEARING_TOOLTIP: "click_anon_hearting_tooltip",
  /**
   * User clicked a CTA that was marked as requiring registration in the CMS entry
   */
  CLICK_CMS_CTA: "click_cms_cta",
  CLICK_COPY_SHORTLIST: "click_copy_shortlist",
  CLICK_GATED_HP_NAV_SIGN_IN: "click_nav_sign_in_icon_ghp",
  CLICK_LOGIN_ON_LIMITED_ACCOUNT_CHECKOUT: "click_login_on_limited_account_checkout",
  CLICK_HEART: "click_heart",
  CLICK_KIF_ATB: "click_kif_atb",
  CLICK_NAV_BAG_ICON: "click_nav_bag_icon",
  CLICK_NAV_HEART_ICON: "click_nav_heart_icon",
  CLICK_NAV_SIGN_IN: "click_nav_sign_in_icon",
  CLICK_ADD_PLAN_TO_BAG: "click_add_plan_to_bag",
  /**
   * User signed in from a referral page
   */
  CLICK_REFERRAL_SIGN_IN: "referral_sign_in",
  CLICK_RESERVE_ATB: "click_reserve_atb",
  /**
   * The Ruby layer redirected an anonymous/identified user to auth via a URL query param
   */
  MIDDLEWARE_AUTH_PROMPT: "middleware_auth_prompt",
  UNKNOWN: "unknown",
};

export const HEAP_PDP_DRAWER_CLOSE_EVENT = "grid_pdp_drawer_close";
export const HEAP_DRAWER_TRIGGERS = {
  BACK: "back",
  CLOSE: "closeBtn",
  DRAG: "drag",
};

export const HEAP_NOTIFY_ME_EVENTS = {
  PDP_CTA: "sf-pdp-tap-notify-me-cta",
  PDP_BANNER: "sf-pdp-tap-notify-me-banner",
  SUBSCRIBE: "sf-notify-me-subscribe",
  UNSUBSCRIBE: "sf-notify-me-unsubscribe",
};

export const HEAP_HEART_EVENTS = {
  HEART: "heart",
  UNHEART: "unheart",
};

const PLANS_EXP_EVENT_NAMES = {
  next: "plans-treatment",
  ruby: "plans-control",
};
const RETURNING_HOME_EXP_EVENT_NAMES = {
  next: "returning-home-treatment",
  ruby: "returning-home-control",
};
const MEMBER_HOME_EXP_EVENT_NAMES = {
  next: "member-home-treatment",
  ruby: "member-home-control",
};
const CLEARANCE_GRID_EXP_EVENT_NAMES = {
  next: "clearance-grid-treatment",
  ruby: "clearance-grid-control",
};
const PDP_EXP_EVENT_NAMES = {
  next: "pdp-treatment",
  ruby: "pdp-control",
};
const NEXT_EXPERIMENT_EVENT_NAMES_BY_PATH = {
  ["/clothing-subscription"]: PLANS_EXP_EVENT_NAMES,
  ["/choose-plan"]: PLANS_EXP_EVENT_NAMES,
  ["/home/returning"]: RETURNING_HOME_EXP_EVENT_NAMES,
  ["/home/member"]: MEMBER_HOME_EXP_EVENT_NAMES,
};
const NEXT_EXPERIMENT_EVENT_NAMES_BY_PATH_REGEX = [
  { regex: /\/shop\/designers\/[a-z_]+\/[a-z_]+/, value: PDP_EXP_EVENT_NAMES },
  { regex: /^\/clearance\/products/, value: CLEARANCE_GRID_EXP_EVENT_NAMES },
];

/**
 * The Storefront constants use upper snake case, analytics asked for this format instead.
 */
export const RENTAL_INTENT = {
  MEMBERSHIP: "membership",
  RESERVE: "reserve",
  NO_INTENT: "no-intent",
};

const NEXT_EXPERIMENT_EVENT_NAMES_BY_PATH_REGEX_AND_INTENT = [
  { regex: /\/shop\/[a-z_]+\/products/, gridType: "curation-grids" },
  { regex: /\/pdp\/shop\/[a-z_]+\/products/, gridType: "curation-grids" },
  { regex: /\/designers\/[a-z_]+\/products/, gridType: "designer-grids" },
  { regex: /\/designers\/[a-z_]+/, gridType: "designer-grids" },
  { regex: /\/pages\/designers\/[a-z_]+\/products/, gridType: "designer-grids" },
  { regex: /\/products\/*/, gridType: "category-grids" },
  { regex: /\/browse\/*/, gridType: "category-grids" },
];

const generateEventNameForGrids = (gridType, env, intent) => {
  return `${gridType || ""}-${intent || ""}-${env === "next" ? "treatment" : "control"}`;
};

const parseIntent = info => {
  if (info?.includes(membershipLens.unlimited)) {
    return RENTAL_INTENT.MEMBERSHIP;
  } else if (info?.includes(membershipLens.RTRUpdate)) {
    return RENTAL_INTENT.MEMBERSHIP;
  } else if (info?.includes(membershipLens.classic)) {
    return RENTAL_INTENT.RESERVE;
  } else {
    return RENTAL_INTENT.NO_INTENT;
  }
};

const deriveIntentFromUrlOrCookie = (initialUrl, intentFromCookie) => {
  const filterstrings = [
    `lens=${RENTAL_INTENT.NO_INTENT}`,
    `lens=${membershipLens.unlimited}`,
    `lens=${membershipLens.classic}`,
    `lens=${membershipLens.RTRUpdate}`,
  ];
  const regex = new RegExp(filterstrings.join("|"), "i");

  if (regex.test(initialUrl)) {
    return parseIntent(initialUrl);
  } else {
    return parseIntent(intentFromCookie);
  }
};

const HeapHelpers = {
  fireHeapEvent: (eventName = null, properties) => {
    if (!properties) {
      return;
    }
    invokeHeapFunctionWithTryCatch(() => window?.heap.track(eventName, { ...properties, ...pixelServiceProperties() }));
  },

  addEventProperties: propertiesHash => {
    if (!propertiesHash) {
      return;
    }

    invokeHeapFunctionWithTryCatch(() => {
      window?.heap.addEventProperties(propertiesHash);
    });
  },

  addUserProperties: propertiesHash => {
    if (!propertiesHash) {
      return;
    }

    invokeHeapFunctionWithTryCatch(() => window?.heap.addUserProperties(propertiesHash));
  },

  clearEventProperties: () => {
    invokeHeapFunctionWithTryCatch(() => {
      window?.heap.clearEventProperties();
    });
  },

  clearEventPropertiesAndIdentity: () => {
    invokeHeapFunctionWithTryCatch(() => {
      window?.heap.clearEventProperties();
      window?.heap.resetIdentity();
    });
  },

  identifyUser: userId => {
    if (!userId) {
      return;
    }

    invokeHeapFunctionWithTryCatch(() => window?.heap.identify(userId.toString()));
  },

  trackIntentChange: function (lens) {
    const intent = isSubscriptionLens(lens) ? RENTAL_INTENT.MEMBERSHIP : RENTAL_INTENT.RESERVE;

    this.fireHeapEvent("intent_changed", { intent });
  },

  /**
   * Update properties when users change lens on a page.
   */
  updateUserIntent: function (lens) {
    const properties = {
      intent: isSubscriptionLens(lens) ? RENTAL_INTENT.MEMBERSHIP : RENTAL_INTENT.RESERVE,
    };

    this.addEventProperties(properties);
    this.addUserProperties(properties);
  },

  setCurrentCustomerStatus: function (membershipState = {}, userData = {}) {
    const is_customer = Boolean(userData?.userProfile?.isCustomer);
    const is_reserve_only_customer = is_customer && !Object.keys(membershipState).length;
    const is_churned_subscriber = isCanceled(membershipState);
    const is_subscriber = isActiveMembership(membershipState);

    this.addEventProperties({ is_customer, is_reserve_only_customer, is_churned_subscriber, is_subscriber });
  },

  pageLoadPropertiesAreSet: false,
  //Getter/setter paradigm here is mostly to ease testing
  getPageLoadPropertiesAreSet() {
    return this.pageLoadPropertiesAreSet;
  },
  setPageLoadPropertiesAreSet(propertiesAreSet) {
    this.pageLoadPropertiesAreSet = propertiesAreSet;
  },

  pageLoadPropertiesReady(membershipState, userData) {
    const userProfileReady = userData?.userProfileHasLoaded;

    return membershipState && userProfileReady;
  },

  /**
   * Add properties tracked on all events. This is invoked when mounting UserMenu.
   * Only invoked once per page load, unless auth state changes (i.e. shouldForceOverwrite === true)
   */
  setPropertiesOnPageLoad: function (membershipState, userData, experiments = {}, shouldForceOverwrite = false) {
    if (
      !shouldForceOverwrite &&
      (this.getPageLoadPropertiesAreSet() || !this.pageLoadPropertiesReady(membershipState, userData))
    ) {
      return;
    }

    this.setPageLoadPropertiesAreSet(true);
    this.clearEventProperties();

    this.identifyUser(getUserId(userData));

    this.setCurrentCustomerStatus(membershipState, userData);

    const globalProperties = {
      authenticated: isIdentified(userData),
      customer: Boolean(userData?.userProfile?.isCustomer), //isCustomer is undefined for anon users, which Heap ignores,
      subscription_active: isActiveMembership(membershipState),
    };

    const membershipProperties = this.getMembershipPropertiesForHeap(membershipState);

    if (isSubscriptionLens(userData)) {
      globalProperties.intent = RENTAL_INTENT.MEMBERSHIP;
    } else if (isClassicLens(userData)) {
      globalProperties.intent = RENTAL_INTENT.RESERVE;
    } else {
      globalProperties.intent = RENTAL_INTENT.NO_INTENT;
    }
    const experimentsProperties = this.getExperimentsProperties(experiments) || {};
    const browserProperties = this.getBrowserProperties();

    this.addUserProperties({
      ...globalProperties,
      ...experimentsProperties,
      ...membershipProperties.userProperties,
    });
    this.addEventProperties({
      ...globalProperties,
      ...experimentsProperties,
      ...membershipProperties.eventProperties,
      ...pixelServiceProperties(),
      ...browserProperties,
    });
  },

  getBrowserProperties: function () {
    const parser = new UAParser(navigator.userAgent);

    return {
      browser: parser.getBrowser().name,
      platform: parser.getOS().name,
      device: parser.getDevice().type,
    };
  },

  getMembershipPropertiesForHeap: function (membershipState = {}) {
    const membershipProperties = {};
    let userProperties = {};
    let eventProperties = {};
    if (isActiveMembership(membershipState)) {
      membershipProperties.subscription_status = membershipState.billingStatus;

      membershipProperties.monthly_shipment_plan = membershipState.monthlyShipmentLimit;

      membershipProperties.current_shipment_number_monthly =
        membershipState.monthlyShipmentLimit - membershipState.shipmentsCount;

      membershipProperties.slot_plan = membershipState.baseSlotCount;

      // we use membershipTierRevisionId over membership_tier_id to show the most active tier revision
      membershipProperties.membership_tier_id = membershipState.membershipTierRevisionId;

      const replacementSlots = membershipState.slots.filter(slot => slot.type === membershipSlotTypes.REPLACEMENT);
      membershipProperties.number_replacement_slots = replacementSlots.length;

      const termStartDate = parseISOWithoutTime(membershipState.termStart);
      const daysIntoTerm = differenceInDays(Date.now(), termStartDate);
      membershipProperties.day_of_membership_term = daysIntoTerm;

      userProperties = {
        ...membershipProperties,
        current_term: membershipState.membershipTermNumber,
      };
      eventProperties = {
        ...membershipProperties,
        term: membershipState.membershipTermNumber,
      };
    } else {
      userProperties = {
        current_term: -1,
      };
      eventProperties = {
        term: -1,
      };
    }

    return {
      userProperties,
      eventProperties,
    };
  },

  getExperimentsProperties: function (experiments = {}) {
    let areAllExperimentsFetched = true;
    const fetchedExperiments = this.getTrackedExperiments().reduce((prev, curr) => {
      /*
       * [DZ] 11/2024
       * If we null and undefined check here instead of a truthy evaluation,
       * we'll be able to pass false experiment values to Heap
       */
      if (typeof experiments[curr] === "undefined" || experiments[curr] === null) {
        areAllExperimentsFetched = false;
      } else {
        prev[curr] = experiments[curr];
      }

      return prev;
    }, {});

    if (!areAllExperimentsFetched) {
      return;
    }

    return fetchedExperiments;
  },

  setMembershipStatePropertiesInHeap: function (membershipState) {
    const membershipProperties = this.getMembershipPropertiesForHeap(membershipState);
    this.addUserProperties({
      ...membershipProperties.userProperties,
    });
    this.addEventProperties({
      ...membershipProperties.eventProperties,
    });
  },

  setExperimentsPropertiesInHeap: function (experiments) {
    const fetchedExperiments = this.getExperimentsProperties(experiments);
    if (fetchedExperiments) {
      this.addUserProperties(fetchedExperiments);
      this.addEventProperties(fetchedExperiments);
    }
  },

  /**
   * Add properties tracked on add and remove from bag.
   */
  setMembershipBagProperties: function (bagCount) {
    const bagProperties = {
      number_items_in_membership_bag: bagCount,
    };

    this.addUserProperties(bagProperties);
    this.addEventProperties(bagProperties);
  },

  trackAddToBag: (productType, productTypeDetail, options = {}) => {
    const eventName = "add_to_bag";
    const properties = {
      ...options,
      productType,
      productTypeDetail,
      next: isStorefrontNext(),
      ...pixelServiceProperties(),
    };

    invokeHeapFunctionWithTryCatch(() => window?.heap.track(eventName, properties));
  },

  trackRemoveFromBag: (bagType, itemId, options = {}) => {
    const eventName = "remove_from_bag";
    const properties = { ...options, bagType, itemId, next: isStorefrontNext(), ...pixelServiceProperties() };

    invokeHeapFunctionWithTryCatch(() => window?.heap.track(eventName, properties));
  },

  trackAuthModalOpen: (triggeredBy, currentPath) => {
    const eventName = "auth_form_rendered";
    const properties = {
      modal_name: "auth_modal",
      source_page: currentPath,
      trigger_by: triggeredBy ?? HEAP_AUTH_TRIGGER_TYPES.UNKNOWN,
      ...pixelServiceProperties(),
    };

    invokeHeapFunctionWithTryCatch(() => window?.heap.track(eventName, properties));
  },

  trackAuthModalClose: (isRegistration, wasDismissed, triggeredBy, currentPath) => {
    let action;
    if (wasDismissed) {
      action = "dismiss";
    } else if (isRegistration) {
      action = "registration";
    } else {
      action = "login";
    }

    const eventName = "auth_form_closed";
    const properties = {
      modal_name: "auth_modal",
      source_page: currentPath,
      trigger_by: triggeredBy ?? HEAP_AUTH_TRIGGER_TYPES.UNKNOWN,
      action,
      ...pixelServiceProperties(),
    };

    invokeHeapFunctionWithTryCatch(() => window?.heap.track(eventName, properties));
  },

  trackAuthComplete: (provider, currentPath) => {
    const eventName = "authentication_complete";
    const properties = {
      modal_name: "auth_modal",
      source_page: currentPath,
      provider: provider,
    };

    invokeHeapFunctionWithTryCatch(() => window?.heap.track(eventName, properties));
  },

  /**
   * Log authentication_complete Heap event after a successful CAS login.
   */
  trackCasAuthComplete: () => {
    const casDetails = cookies.get(COOKIES.LOG_AUTHN_COMPLETED);
    if (casDetails) {
      HeapHelpers.trackAuthComplete(casDetails.provider, getLocationPathname());
    }
    cookies.remove(COOKIES.LOG_AUTHN_COMPLETED, { path: "/" });
  },

  trackCheckoutAttempt: (previewInvoice, promoCode, purchaseDetails) => {
    if (!previewInvoice || !Object.keys(previewInvoice).length) {
      return;
    }

    const eventName = "order";
    buildPurchaseEvents(previewInvoice, promoCode, purchaseDetails).forEach(event => {
      invokeHeapFunctionWithTryCatch(() => window?.heap.track(eventName, { ...event, ...pixelServiceProperties() }));
    });
  },

  /**
   * A list of experiments whose treatment values will be added to the Heap cookie.
   */
  // move experiments into constants in the future.
  getTrackedExperiments: () => [...Object.values(launchDarklyExperiments)],

  /**
   * sends a custom tracking event to heap to differentiate
   * between SF ruby and SF Next served pages
   * @param {string} env - either "next" or "ruby"
   * @param {string} initialUrl - full initial URL set by Fastly
   */
  trackRubyAndNextPageHits: (env, initialUrl) => {
    if (!initialUrl) {
      return;
    }

    const pathname = new URL(initialUrl).pathname;

    const findMatchingEventName = () => {
      const exactMatch = NEXT_EXPERIMENT_EVENT_NAMES_BY_PATH[pathname];
      if (exactMatch) {
        return exactMatch[env];
      }

      for (const pattern of NEXT_EXPERIMENT_EVENT_NAMES_BY_PATH_REGEX) {
        if (pattern.regex.test(pathname)) {
          return pattern.value[env];
        }
      }

      for (const pattern of NEXT_EXPERIMENT_EVENT_NAMES_BY_PATH_REGEX_AND_INTENT) {
        if (pattern.regex.test(pathname)) {
          const intent = deriveIntentFromUrlOrCookie(initialUrl, cookies.get("membership_lens") || "");
          return generateEventNameForGrids(pattern.gridType, env, intent);
        }
      }
    };

    const eventName = findMatchingEventName();
    if (eventName) {
      invokeHeapFunctionWithTryCatch(() => window?.heap.track(eventName, { ...pixelServiceProperties() }));
    }
  },

  trackCarouselLoading: function (type) {
    if (type === carouselTypes.VISUALLY_SIMILAR_TO) {
      this.fireHeapEvent("similar_styles_carousel_loaded", {
        page: "PDP",
        carouselType: carouselTypes.VISUALLY_SIMILAR_TO,
      });
    } else if (type === carouselTypes.OUTFITS) {
      this.fireHeapEvent("rent_the_look_carousel_loaded", { page: "PDP", carouselType: carouselTypes.OUTFITS });
    } else if (type === carouselTypes.SIMILAR_ITEMS) {
      this.fireHeapEvent("you_may_also_like_carousel_loaded", {
        page: "PDP",
        carouselType: carouselTypes.SIMILAR_ITEMS,
      });
    } else if (type === carouselTypes.CLEARANCE) {
      this.fireHeapEvent("clearance_carousel_loaded", { page: "PDP", carouselType: carouselTypes.CLEARANCE });
    }
  },
};

export default HeapHelpers;
