import ProductAvailability from "helpers/product-availability";
import SizeHelper from "helpers/form-field-size-helper";
import { LocalStorage } from "site/localStorage";

export const NOTIFY_ME_LOCAL_STORAGE_NAMESPACE = "notifyMe";
export const NOTIFY_ME_LOCAL_STORAGE_KEY = "existingNotifyMeProducts";

export const getExistingNotifyMeProductSKUs = (product, existingNotifyMeProducts) => {
  if (!product || !existingNotifyMeProducts || !Array.isArray(existingNotifyMeProducts)) return [];
  const alreadySubscribedProduct = existingNotifyMeProducts.find(
    subscribedProduct => subscribedProduct.style === product.id
  );
  return alreadySubscribedProduct ? alreadySubscribedProduct.skus : [];
};

export const isNotifyMeAvailable = (selectedSize, product, membershipState) => {
  const productIsEligbleForUser = ProductAvailability.checkProductIsEligibleForUser(product, membershipState);
  const allSizesAreUnavailable =
    SizeHelper.hasProductSizes(product) && ProductAvailability.allSizesUnavailable(product, membershipState);
  const isOneSizeAndOutOfStock =
    SizeHelper.isOneSizeFitsAll(product) && ProductAvailability.isOneSizeProductOutOfStock(product);
  const selectedSizeIsOutOfStock =
    selectedSize &&
    !SizeHelper.isOneSizeFitsAll(product) &&
    !ProductAvailability.hasSkuCount(selectedSize, product, membershipState);

  return Boolean(
    productIsEligbleForUser && (allSizesAreUnavailable || isOneSizeAndOutOfStock || selectedSizeIsOutOfStock)
  );
};

export const updateNotifyMeLocalStorage = newNotifyMeProducts => {
  const localStorageClient = new LocalStorage(NOTIFY_ME_LOCAL_STORAGE_NAMESPACE);
  localStorageClient.set(NOTIFY_ME_LOCAL_STORAGE_KEY, {
    existingNotifyMeProducts: newNotifyMeProducts,
    timestamp: Date.now(),
  });
};

export const getNotifyMeLocalStorage = () => {
  const localStorageClient = new LocalStorage(NOTIFY_ME_LOCAL_STORAGE_NAMESPACE);
  return localStorageClient.get(NOTIFY_ME_LOCAL_STORAGE_KEY);
};

export const clearNotifyMeLocalStorage = () => {
  const localStorageClient = new LocalStorage(NOTIFY_ME_LOCAL_STORAGE_NAMESPACE);
  localStorageClient.remove(NOTIFY_ME_LOCAL_STORAGE_KEY);
};

// if the productId is in existingNotifyMeProducts, we can assume it is a notify me product
// and we don't need to check for the specific SKU
export const isNotifyMeProduct = (productId, existingNotifyMeProducts) => {
  if (!productId || !existingNotifyMeProducts || !Array.isArray(existingNotifyMeProducts)) return false;
  return existingNotifyMeProducts.some(subscribedProduct => subscribedProduct.style === productId);
};
