// Keep this list sorted alphabetically!
const ActionTypes = {
  ACTIVE_ORDERS_FAILURE: "ACTIVE_ORDERS_FAILURE",
  ACTIVE_ORDERS_LOADING: "ACTIVE_ORDERS_LOADING",
  ACTIVE_ORDERS_SUCCESS: "ACTIVE_ORDERS_SUCCESS",
  ADD_GIFT_CARD_TO_BAG: "ADD_GIFT_CARD_TO_BAG",
  ADD_GIFT_CARD_TO_BAG_BEGIN: "ADD_GIFT_CARD_TO_BAG_BEGIN",
  ADD_GIFT_CARD_TO_BAG_FAILURE: "ADD_GIFT_CARD_TO_BAG_FAILURE",
  ADD_GIFT_CARD_TO_BAG_SUCCESS: "ADD_GIFT_CARD_TO_BAG_SUCCESS",
  ADD_ORDER_ITEM_FAILURE: "ADD_ORDER_ITEM_FAILURE",
  ADD_ORDER_ITEM_SUBMITTING: "ADD_ORDER_ITEM_SUBMITTING",
  ADD_ORDER_ITEM_SUCCESS: "ADD_ORDER_ITEM_SUCCESS",
  ADD_PAGE_ERROR: "ADD_PAGE_ERROR",
  ADD_REJECTED_STYLE_BEGIN: "ADD_REJECTED_STYLE_BEGIN",
  ADD_REJECTED_STYLE_SUCCESS: "ADD_REJECTED_STYLE_SUCCESS",
  ADD_REMAINING_PRODUCTS: "ADD_REMAINING_PRODUCTS",
  ADD_TO_SHORTLIST_FAILURE: "ADD_TO_SHORTLIST_FAILURE",
  ADD_TO_SHORTLIST_SUCCESS: "ADD_TO_SHORTLIST_SUCCESS",
  ADD_UPDATE_ITEMS: "ADD_UPDATE_ITEMS",
  ADDRESS_ADD_ADDRESS: "ADDRESS_ADD_ADDRESS",
  ADDRESS_BOOK_IS_LOADING: "ADDRESS_BOOK_IS_LOADING",
  ADDRESS_BOOK_LOADED: "ADDRESS_BOOK_LOADED",
  ADDRESS_IS_DELETING: "ADDRESS_IS_DELETING",
  ADDRESS_IS_UPDATING: "ADDRESS_IS_UPDATING",
  ADDRESS_REPLACE_ADDRESSES: "ADDRESS_REPLACE_ADDRESSES",
  ADDRESS_UPDATED: "ADDRESS_UPDATED",
  ANALYTICS_PREVIOUS_LOCATION_CONTEXT_SET: "ANALYTICS_PREVIOUS_LOCATION_CONTEXT_SET",
  APPLE_PAY_SESSION_LOADING: "APPLE_PAY_SESSION_LOADING",
  APPLE_PAY_SESSION_SUCCESS: "APPLE_PAY_SESSION_SUCCESS",
  APPLE_PAY_SESSION_FAILURE: "APPLE_PAY_SESSION_FAILURE",
  APPLY_GIFT_CARD_FAILURE: "APPLY_GIFT_CARD_FAILURE",
  APPLY_GIFT_CARD_SUBMITTING: "APPLY_GIFT_CARD_SUBMITTING",
  APPLY_GIFT_CARD_SUCCESS: "APPLY_GIFT_CARD_SUCCESS",
  APPLY_PROMO_FAILURE: "APPLY_PROMO_FAILURE",
  APPLY_PROMO_SUBMITTING: "APPLY_PROMO_SUBMITTING",
  APPLY_PROMO_SUCCESS: "APPLY_PROMO_SUCCESS",
  BAG_IS_OPEN: "BAG_IS_OPEN",
  BAG_PRO_DISMISSED: "BAG_PRO_DISMISSED",
  BAG_SWITCH_TAB: "BAG_SWITCH_TAB",
  BAG_TAB_ID: "BAG_TAB_ID",
  BILLING_STEP_CLEAR_ADDRESS_FIELDS: "BILLING_STEP_CLEAR_ADDRESS_FIELDS",
  BILLING_STEP_CLEAR_ELEMENT_VALUES: "BILLING_STEP_CLEAR_ELEMENT_VALUES",
  BILLING_STEP_FAILURE: "BILLING_STEP_FAILURE",
  BILLING_STEP_SET_VALUES_FOR_EDIT: "BILLING_STEP_SET_VALUES_FOR_EDIT",
  BILLING_STEP_SUBMITTING: "BILLING_STEP_SUBMITTING",
  BILLING_STEP_SUCCESS: "BILLING_STEP_SUCCESS",
  BILLING_STEP_UPDATE_ELEMENT: "BILLING_STEP_UPDATE_ELEMENT",
  BILLING_STEP_USE_SHIPPING_ADDRESS: "BILLING_STEP_USE_SHIPPING_ADDRESS",
  BILLING_STEP_VALIDATE_BEFORE_SUBMIT: "BILLING_STEP_VALIDATE_BEFORE_SUBMIT",
  CANCEL_RESERVATION_SUBMISSION: "CANCEL_RESERVATION_SUBMISSION",
  CANCEL_POST_PENDING: "CANCEL_POST_PENDING",
  CART_ABANDONMENT_STATE_SET: "CART_ABANDONMENT_STATE_SET",
  CE_BAG_COUNT: "CE_BAG_COUNT",
  CE_BAG_ERROR: "CE_BAG_ERROR",
  CE_BAG_LOADING: "CE_BAG_LOADING",
  CE_BAG_SUCCESS: "CE_BAG_SUCCESS",
  CE_CHECKOUT_LOADING: "CE_CHECKOUT_LOADING",
  CE_CHECKOUT_SUCCESS: "CE_CHECKOUT_SUCCESS",
  CE_CHECKOUT_KIFAH_SUCCESS: "CE_CHECKOUT_KIFAH_SUCCESS",
  CE_CHECKOUT_SUBMITTING: "CE_CHECKOUT_SUBMITTING",
  CE_CHECKOUT_ERROR: "CE_CHECKOUT_ERROR",
  CE_CHECKOUT_ID_CURRENTLY_CREATING: "CE_CHECKOUT_ID_CURRENTLY_CREATING",
  CE_CHECKOUT_PROMO_ERROR: "CE_CHECKOUT_PROMO_ERROR",
  CE_CHECKOUT_PROMO_LOADING: "CE_CHECKOUT_PROMO_LOADING",
  CE_CHECKOUT_USER_PAYMENT_METHODS_SUCCESS: "CE_CHECKOUT_USER_PAYMENT_METHODS_SUCCESS",
  CE_CHECKOUT_PLACE_ORDER_DISABLED: "CE_CHECKOUT_PLACE_ORDER_DISABLED",
  CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_LOADING: "CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_LOADING",
  CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_SUCCESS: "CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_SUCCESS",
  CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_ERROR: "CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_ERROR",
  CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_EXISTS: "CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_EXISTS",
  CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_CHALLENGED: "CE_LIMITED_ACCOUNT_CHECKOUT_EMAIL_CHALLENGED",
  CHANGE_CATEGORY: "CHANGE_CATEGORY",
  CHANGE_CATEGORY_INJECTED_LINK: "CHANGE_CATEGORY_INJECTED_LINK",
  CHANGE_DATE: "CHANGE_DATE",
  CHANGE_FILTERS: "CHANGE_FILTERS",
  CHANGE_PAGE: "CHANGE_PAGE",
  CHANGE_SORT: "CHANGE_SORT",
  CHECKOUT_BILLING_STEP_FAILURE: "CHECKOUT_BILLING_STEP_FAILURE",
  CHECKOUT_BILLING_STEP_SUBMITTING: "CHECKOUT_BILLING_STEP_SUBMITTING",
  CHECKOUT_BILLING_STEP_SUCCESS: "CHECKOUT_BILLING_STEP_SUCCESS",
  CHECKOUT_SHIPPING_STEP_FAILURE: "CHECKOUT_SHIPPING_STEP_FAILURE",
  CHECKOUT_SHIPPING_STEP_SUBMITTING: "CHECKOUT_SHIPPING_STEP_SUBMITTING",
  CHECKOUT_SHIPPING_STEP_SUCCESS: "CHECKOUT_SHIPPING_STEP_SUCCESS",
  CHECK_HOME_PICKUP_ELIGIBILITY_ERROR: "CHECK_HOME_PICKUP_ELIGIBILITY_ERROR",
  CHECK_HOME_PICKUP_ELIGIBILITY_SUCCESS: "CHECK_HOME_PICKUP_ELIGIBILITY_SUCCESS",
  CLEARANCE_CAROUSEL_SUCCESS: "CLEARANCE_CAROUSEL_SUCCESS",
  CLEAR_DELIVERY_CUTOFF_VALUE: "CLEAR_DELIVERY_CUTOFF_VALUE",
  CLEAR_FILTERS: "CLEAR_FILTERS",
  CLEAR_HOLD: "CLEAR_HOLD",
  CLEAR_HOLD_PREFERENCES_SUCCESS: "CLEAR_HOLD_PREFERENCES_SUCCESS",
  CLEAR_PAGE_ERROR: "CLEAR_PAGE_ERROR",
  CLEAR_REVIEWS_COVER_PHOTOS: "CLEAR_REVIEWS_COVER_PHOTOS",
  CLEAR_REVIEW_PHOTOS: "CLEAR_REVIEW_PHOTOS",
  CLEAR_REFERRAL_REMINDER_MODAL_DATA: "CLEAR_REFERRAL_REMINDER_MODAL_DATA",
  CLEAR_SSC_FLOW: "CLEAR_SSC_FLOW",
  CLONE_SHORTLIST_BEGIN: "CLONE_SHORTLIST_BEGIN",
  CLONE_SHORTLIST_ERROR: "CLONE_SHORTLIST_ERROR",
  CLONE_SHORTLIST_SUCCESS: "CLONE_SHORTLIST_SUCCESS",
  CMS_CONTENT_UPDATED: "CMS_CONTENT_UPDATED",
  COLLECTION_CAROUSEL_SUCCESS: "COLLECTION_CAROUSEL_SUCCESS",
  COMMENT_SUBMIT_SUCCESS: "COMMENT_SUBMIT_SUCCESS",
  CREATE_SHORTLIST_ERROR: "CREATE_SHORTLIST_ERROR",
  CREATE_SHORTLIST_SUCCESS: "CREATE_SHORTLIST_SUCCESS",
  CROP_REVIEW_PHOTO_FAILURE: "CROP_REVIEW_PHOTO_FAILURE",
  CROP_REVIEW_PHOTO_LOADING: "CROP_REVIEW_PHOTO_LOADING",
  CROP_REVIEW_PHOTO_SUCCESS: "CROP_REVIEW_PHOTO_SUCCESS",
  DELETE_ADDRESS_FAILURE: "DELETE_ADDRESS_FAILURE",
  DELETE_ADDRESS_REQUEST: "DELETE_ADDRESS_REQUEST",
  DELETE_ADDRESS_SUCCESS: "DELETE_ADDRESS_SUCCESS",
  DELETE_REVIEW_PHOTO_SUCCESS: "DELETE_REVIEW_PHOTO_SUCCESS",
  DELETE_SHORTLIST_FAILURE: "DELETE_SHORTLIST_FAILURE",
  DELETE_SHORTLIST_SUCCESS: "DELETE_SHORTLIST_SUCCESS",
  DISMISS_SNACK_BAR: "DISMISS_SNACK_BAR",
  DISPLAY_HOME_PICKUP: "DISPLAY_HOME_PICKUP",
  DISPLAY_HOME_PICKUP_SERVER_ERROR: "DISPLAY_HOME_PICKUP_SERVER_ERROR",
  DISPLAY_HOME_PICKUP_OVERLAY: "DISPLAY_HOME_PICKUP_OVERLAY",
  DISPLAY_HOME_PICKUP_CONFIRMATION_MODAL: "DISPLAY_HOME_PICKUP_CONFIRMATION_MODAL",
  DISPLAY_HOME_PICKUP_UNAVAILABLE_MODAL: "DISPLAY_HOME_PICKUP_UNAVAILABLE_MODAL",
  DISPLAY_HOME_PICKUP_ALREADY_SCHEDULED: "DISPLAY_HOME_PICKUP_ALREADY_SCHEDULED",
  DISPLAY_HOME_PICKUP_NO_ACTIVE_ORDERS: "DISPLAY_HOME_PICKUP_NO_ACTIVE_ORDERS",
  DISPLAY_MODAL: "DISPLAY_MODAL",
  DISPLAY_HIGHLIGHTED_COMPONENT: "DISPLAY_HIGHLIGHTED_COMPONENT",
  DISPLAY_REPLACEMENTS_PRODUCT_DRAWER: "DISPLAY_REPLACEMENTS_PRODUCT_DRAWER",
  FAVORITE_PRODUCT_ERROR: "FAVORITE_PRODUCT_ERROR",
  FETCH_BAG: "FETCH_BAG",
  FETCH_BLACKOUT_DAYS_ERROR: "FETCH_BLACKOUT_DAYS_ERROR",
  FETCH_BLACKOUT_DAYS_SUCCESS: "FETCH_BLACKOUT_DAYS_SUCCESS",
  FETCH_CROSS_SHIP_RETURN_BY_DATE_FOR_TODAY_SUCCESS: "FETCH_CROSS_SHIP_RETURN_BY_DATE_FOR_TODAY_SUCCESS",
  FETCH_CAROUSEL: "FETCH_CAROUSEL",
  FETCH_CAROUSEL_SUCCESS: "FETCH_CAROUSEL_SUCCESS",
  FETCH_CAROUSEL_FAILURE: "FETCH_CAROUSEL_FAILURE",
  FETCH_CUSTOMER_ATTRIBUTION_SURVEY_OPTIONS_SUCCESS: "FETCH_CUSTOMER_ATTRIBUTION_SURVEY_OPTIONS_SUCCESS",
  FETCH_DELIVERY_CUTOFF_ERROR: "FETCH_DELIVERY_CUTOFF_ERROR",
  FETCH_DELIVERY_CUTOFF_LOADING: "FETCH_DELIVERY_CUTOFF_LOADING",
  FETCH_DELIVERY_CUTOFF_SUCCESS: "FETCH_DELIVERY_CUTOFF_SUCCESS",
  FETCH_EXCHANGE_CONTENT: "FETCH_EXCHANGE_CONTENT",
  FETCH_EXCHANGE_CONTENT_BEGIN: "FETCH_EXCHANGE_CONTENT_BEGIN",
  FETCH_EXCHANGE_CONTENT_FAILURE: "FETCH_EXCHANGE_CONTENT_FAILURE",
  FETCH_EXCHANGE_CONTENT_SUCCESS: "FETCH_EXCHANGE_CONTENT_SUCCESS",
  FETCH_FAVORITES_ERROR: "FETCH_FAVORITES_ERROR",
  FETCH_FAVORITES_SUCCESS: "FETCH_FAVORITES_SUCCESS",
  FETCH_FIT_SUMMARY: "FETCH_FIT_SUMMARY",
  FETCH_FIT_SUMMARY_ERROR: "FETCH_FIT_SUMMARY_ERROR",
  FETCH_FIT_SUMMARY_SUCCESS: "FETCH_FIT_SUMMARY_SUCCESS",
  FETCH_FLAG_OR_EXPERIMENT_SUCCESS: "FETCH_FLAG_OR_EXPERIMENT_SUCCESS",
  FETCH_FLAG_OR_EXPERIMENT_LOADING: "FETCH_FLAG_OR_EXPERIMENT_LOADING",
  FETCH_FLAG_OR_EXPERIMENT_ERROR: "FETCH_FLAG_OR_EXPERIMENT_ERROR",
  FETCH_HAPPINESS_SURVEYS_COMPLETED_SUCCESS: "FETCH_HAPPINESS_SURVEYS_COMPLETED_SUCCESS",
  FETCH_HAPPINESS_SURVEYS_SUCCESS: "FETCH_HAPPINESS_SURVEYS_SUCCESS",
  FETCH_HOLD_PREFERENCES_SUCCESS: "FETCH_HOLD_PREFERENCES_SUCCESS",
  FETCH_HOMEPAGE_ERROR: "FETCH_HOMEPAGE_ERROR",
  FETCH_HOMEPAGE_SUCCESS: "FETCH_HOMEPAGE_SUCCESS",
  FETCH_MEMBERSHIP_RENTAL_BEGIN_SUCCESS: "FETCH_MEMBERSHIP_RENTAL_BEGIN_SUCCESS",
  FETCH_NEAREST_STORE_ERROR: "FETCH_NEAREST_STORE_ERROR",
  FETCH_NEAREST_STORE_LOADING: "FETCH_NEAREST_STORE_LOADING",
  FETCH_NEAREST_STORE_SUCCESS: "FETCH_NEAREST_STORE_SUCCESS",
  FETCH_PENDING_REVIEWS_SUCCESS: "FETCH_PENDING_REVIEWS_SUCCESS",
  FETCH_PERSONALIZED_CAROUSELS: "FETCH_PERSONALIZED_CAROUSELS",
  FETCH_PERSONALIZED_CAROUSELS_FAILURE: "FETCH_PERSONALIZED_CAROUSELS_FAILURE",
  FETCH_PERSONALIZED_CAROUSELS_SUCCESS: "FETCH_PERSONALIZED_CAROUSELS_SUCCESS",
  FETCH_PRO_MEMBERSHIP_SUCCESS: "FETCH_PRO_MEMBERSHIP_SUCCESS",
  FETCH_PRODUCT_AVAILABILITY_FOR_CAROUSELS_SUCCESS: "FETCH_PRODUCT_AVAILABILITY_FOR_CAROUSELS_SUCCESS",
  FETCH_PRODUCTS_BY_STYLES_BEGIN: "FETCH_PRODUCTS_BY_STYLES_BEGIN",
  FETCH_PRODUCTS_BY_STYLES_FAILURE: "FETCH_PRODUCTS_BY_STYLES_FAILURE",
  FETCH_PRODUCTS_BY_STYLES_SUCCESS: "FETCH_PRODUCTS_BY_STYLES_SUCCESS",
  FETCH_RTR_CONTENT_MODULE_SUCCESS: "FETCH_RTR_CONTENT_MODULE_SUCCESS",
  FETCH_SHORTLISTS_ERROR: "FETCH_SHORTLISTS_ERROR",
  FETCH_SHORTLISTS_SUCCESS: "FETCH_SHORTLISTS_SUCCESS",
  FETCH_SSE_ACTIVE_ORDERS_SUCCESS: "FETCH_SSE_ACTIVE_ORDERS_SUCCESS",
  FETCH_SUBSCRIPTION_SKUS_FAILURE: "FETCH_SUBSCRIPTION_SKUS_FAILURE",
  FETCH_SUBSCRIPTION_SKUS_SUCCESS: "FETCH_SUBSCRIPTION_SKUS_SUCCESS",
  FETCH_USER_CREDITS_SUCCESS: "FETCH_USER_CREDITS_SUCCESS",
  FETCH_USER_PROBLEM_CONTENT: "FETCH_USER_PROBLEM_CONTENT",
  FETCH_USER_PROBLEM_CONTENT_BEGIN: "FETCH_USER_PROBLEM_CONTENT_BEGIN",
  FETCH_USER_PROBLEM_CONTENT_FAILURE: "FETCH_USER_PROBLEM_CONTENT_FAILURE",
  FETCH_USER_PROBLEM_CONTENT_SUCCESS: "FETCH_USER_PROBLEM_CONTENT_SUCCESS",
  FETCH_USER_REVIEW_SUMMARY_FAILURE: "FETCH_USER_REVIEW_SUMMARY_FAILURE",
  FETCH_USER_REVIEWS_SUCCESS: "FETCH_USER_REVIEWS_SUCCESS",
  FETCHING_DEFAULT_SHIPPING_ADDRESS: "FETCHING_DEFAULT_SHIPPING_ADDRESS",
  GOOGLE_MAPS_LOADED: "GOOGLE_MAPS_LOADED",
  HAPPINESS_SURVEY_ADD_CAPTION: "HAPPINESS_SURVEY_ADD_CAPTION",
  HAPPINESS_SURVEY_ADD_COMMENT: "HAPPINESS_SURVEY_ADD_COMMENT",
  HAPPINESS_SURVEY_ADD_FEEDBACK: "HAPPINESS_SURVEY_ADD_FEEDBACK",
  HAPPINESS_SURVEY_ADD_RATING: "HAPPINESS_SURVEY_ADD_RATING",
  HAPPINESS_SURVEY_ADD_REVIEW_CONTENT: "HAPPINESS_SURVEY_ADD_REVIEW_CONTENT",
  HAPPINESS_SURVEY_COMPLETED: "HAPPINESS_SURVEY_COMPLETED",
  HAPPINESS_SURVEY_FETCH: "HAPPINESS_SURVEY_FETCH",
  HAPPINESS_SURVEY_REMOVE_FEEDBACK: "HAPPINESS_SURVEY_REMOVE_FEEDBACK",
  HAPPINESS_SURVEY_REMOVE_ALL_FEEDBACK: "HAPPINESS_SURVEY_REMOVE_ALL_FEEDBACK",
  HAPPINESS_SURVEY_SET_ITEM_IN_PROGRESS: "HAPPINESS_SURVEY_SET_ITEM_IN_PROGRESS",
  HAPPINESS_SURVEYS_BY_STYLE_BEGIN: "HAPPINESS_SURVEYS_BY_STYLE_BEGIN",
  HAPPINESS_SURVEYS_BY_STYLE_FAILURE: "HAPPINESS_SURVEYS_BY_STYLE_FAILURE",
  HAPPINESS_SURVEYS_BY_STYLE_SUCCESS: "HAPPINESS_SURVEYS_BY_STYLE_SUCCESS",
  HAS_FILE_SIZE_ERROR: "HAS_FILE_SIZE_ERROR",
  HAS_VIEWED_ATTRIBUTION_SURVEY: "HAS_VIEWED_ATTRIBUTION_SURVEY",
  HEART_EDUCATION_TOOLTIP_DISMISSED: "HEART_EDUCATION_TOOLTIP_DISMISSED",
  HIDE_GENERIC_MODAL: "HIDE_GENERIC_MODAL",
  HIDE_GRID_EDUCATION_TOOLTIP: "HIDE_GRID_EDUCATION_TOOLTIP",
  HOLD_PREFERENCES_ERROR: "HOLD_PREFERENCES_ERROR",
  HOME_HERO_UNIT_SUCCESS: "HOME_HERO_UNIT_SUCCESS",
  HOME_PICKUP_LOADING: "HOME_PICKUP_LOADING",
  HOMEPAGE_CAROUSEL_PRODUCTS_SUCCESS: "HOMEPAGE_CAROUSEL_PRODUCTS_SUCCESS",
  HOMEPAGE_CAROUSEL_PRODUCTS_FAILURE: "HOMEPAGE_CAROUSEL_PRODUCTS_FAILURE",
  IMMEDIATE_UPGRADE_PLAN_PREVIEW: "IMMEDIATE_UPGRADE_PLAN_PREVIEW",
  IMMEDIATE_UPGRADE_PLAN_PREVIEW_FAIL: "IMMEDIATE_UPGRADE_PLAN_PREVIEW_FAIL",
  IMMEDIATE_UPGRADE_PLAN_PREVIEW_LOADING: "IMMEDIATE_UPGRADE_PLAN_PREVIEW_LOADING",
  IN_SSC_FLOW: "IN_SSC_FLOW",
  ITEM_PURCHASE_CHARGE_CANCEL: "ITEM_PURCHASE_CHARGE_CANCEL",
  ITEM_PURCHASE_CHARGE_DONE: "ITEM_PURCHASE_CHARGE_DONE",
  ITEM_PURCHASE_CHARGE_FAIL: "ITEM_PURCHASE_CHARGE_FAIL",
  ITEM_PURCHASE_CHARGE_INIT: "ITEM_PURCHASE_CHARGE_INIT",
  ITEM_PURCHASE_CHARGE_INIT_FAIL: "ITEM_PURCHASE_CHARGE_INIT_FAIL",
  ITEM_PURCHASE_CHARGE_LOADING: "ITEM_PURCHASE_CHARGE_LOADING",
  KIFAH_AVAILABLE_ITEMS: "KIFAH_AVAILABLE_ITEMS",
  KIFAH_TRIGGER: "KIFAH_TRIGGER",
  MEMBER_PROGRAM_CHANGE_INFO: "MEMBER_PROGRAM_CHANGE_INFO",
  MEMBER_PROGRAM_CHANGE_INFO_FAIL: "MEMBER_PROGRAM_CHANGE_INFO_FAIL",
  MEMBER_PROGRAM_CHANGE_INFO_LOADING: "MEMBER_PROGRAM_CHANGE_INFO_LOADING",
  LANDING_PAGE_CAROUSEL_PRODUCTS_FAILURE: "LANDING_PAGE_CAROUSEL_PRODUCTS_FAILURE",
  LANDING_PAGE_CAROUSEL_PRODUCTS_SUCCESS: "LANDING_PAGE_CAROUSEL_PRODUCTS_SUCCESS",
  LOAD_SUCCESS: "LOAD_SUCCESS",
  LOADING: "LOADING",
  LOADING_MEMBERSHIP_RENTAL_BEGIN: "LOADING_MEMBERSHIP_RENTAL_BEGIN",
  LOADING_REVIEW_PHOTOS: "LOADING_REVIEW_PHOTOS",
  LOADING_ROTATED_REVIEW_PHOTO: "LOADING_ROTATED_REVIEW_PHOTO",
  LOYALTY_AWARDS_FETCH: "LOYALTY_AWARDS_FETCH",
  LOYALTY_AWARDS_UPDATE: "LOYALTY_AWARDS_UPDATE",
  LOYALTY_AWARDS_LOADING: "LOYALTY_AWARDS_LOADING",
  LOYALTY_AWARDS_FAILED: "LOYALTY_AWARDS_FAILED",
  MEMBERSHIP_ADD_TO_CART_FAILURE: "MEMBERSHIP_ADD_TO_CART_FAILURE",
  MEMBERSHIP_ADD_TO_CART_LOADING: "MEMBERSHIP_ADD_TO_CART_LOADING",
  MEMBERSHIP_ADD_TO_CART_SUCCESS: "MEMBERSHIP_ADD_TO_CART_SUCCESS",
  MEMBERSHIP_BAG_CLEAR_ERRORS: "MEMBERSHIP_BAG_CLEAR_ERRORS",
  MEMBERSHIP_BAG_LOAD_SUCCESS: "MEMBERSHIP_BAG_LOAD_SUCCESS",
  MEMBERSHIP_BAG_LOADING: "MEMBERSHIP_BAG_LOADING",
  MEMBERSHIP_CHANGE_PLAN_PREVIEW_ALL_LOAD_SUCCESS: "MEMBERSHIP_CHANGE_PLAN_PREVIEW_ALL_LOAD_SUCCESS",
  MEMBERSHIP_CHANGE_PLAN_PREVIEW_FAIL: "MEMBERSHIP_CHANGE_PLAN_PREVIEW_FAIL",
  MEMBERSHIP_CHANGE_PLAN_PREVIEW_LOAD_SUCCESS: "MEMBERSHIP_CHANGE_PLAN_PREVIEW_LOAD_SUCCESS",
  MEMBERSHIP_CHANGE_PLAN_PREVIEW_LOADING: "MEMBERSHIP_CHANGE_PLAN_PREVIEW_LOADING",
  MEMBERSHIP_CHARGE_EXECUTE_FAILURE: "MEMBERSHIP_CHARGE_EXECUTE_FAILURE",
  MEMBERSHIP_CHARGE_EXECUTE_PENDING: "MEMBERSHIP_CHARGE_EXECUTE_PENDING",
  MEMBERSHIP_CHARGE_EXECUTE_SUCCESS: "MEMBERSHIP_CHARGE_EXECUTE_SUCCESS",
  MEMBERSHIP_CHARGE_GET_AMOUNT_FAILURE: "MEMBERSHIP_CHARGE_GET_AMOUNT_FAILURE",
  MEMBERSHIP_CHARGE_GET_AMOUNT_PENDING: "MEMBERSHIP_CHARGE_GET_AMOUNT_PENDING",
  MEMBERSHIP_CHARGE_GET_AMOUNT_SUCCESS: "MEMBERSHIP_CHARGE_GET_AMOUNT_SUCCESS",
  MEMBERSHIP_CHARGE_GET_CHANGE_TIER_AMOUNT_SUCCESS: "MEMBERSHIP_CHARGE_GET_CHANGE_TIER_AMOUNT_SUCCESS",
  MEMBERSHIP_CHECKOUT_FAILURE: "MEMBERSHIP_CHECKOUT_FAILURE",
  MEMBERSHIP_CHECKOUT_IN_PROGRESS: "MEMBERSHIP_CHECKOUT_IN_PROGRESS",
  MEMBERSHIP_CREATE_CHANGE_TIER_PAYMENT_ORDER_PENDING: "MEMBERSHIP_CREATE_CHANGE_TIER_PAYMENT_ORDER_PENDING",
  MEMBERSHIP_REMOVE_FROM_CART_FAILURE: "MEMBERSHIP_REMOVE_FROM_CART_FAILURE",
  MEMBERSHIP_REMOVE_FROM_CART_SUCCESS: "MEMBERSHIP_REMOVE_FROM_CART_SUCCESS",
  MEMBERSHIP_STATE_CHANGE_TIER_SUCCESS: "MEMBERSHIP_STATE_CHANGE_TIER_SUCCESS",
  MEMBERSHIP_STATE_LOAD_SUCCESS: "MEMBERSHIP_STATE_LOAD_SUCCESS",
  MEMBERSHIP_STATE_SUBMITTING_ACTIONS: "MEMBERSHIP_STATE_SUBMITTING_ACTIONS",
  MEMBERSHIP_TIER_LOAD_FAILURE: "MEMBERSHIP_TIER_LOAD_FAILURE",
  MEMBERSHIP_TIER_LOAD_SUCCESS: "MEMBERSHIP_TIER_LOAD_SUCCESS",
  MEMBERSHIP_TIER_LOADING: "MEMBERSHIP_TIER_LOADING",
  MEMBERSHIP_TIERS_LOAD_FAILURE: "MEMBERSHIP_TIERS_LOAD_FAILURE",
  MEMBERSHIP_TIERS_LOAD_SUCCESS: "MEMBERSHIP_TIERS_LOAD_SUCCESS",
  MEMBERSHIP_TIERS_LOADING: "MEMBERSHIP_TIERS_LOADING",
  MID_GRID_CONTENT_FAILURE: "MID_GRID_CONTENT_FAILURE",
  MID_GRID_CONTENT_LOADING: "MID_GRID_CONTENT_LOADING",
  MID_GRID_CONTENT_SUCCESS: "MID_GRID_CONTENT_SUCCESS",
  MODAL_HASH_CHANGE: "MODAL_HASH_CHANGE",
  ONE_TIME_EXPERIENCE_DISMISSED: "ONE_TIME_EXPERIENCE_DISMISSED",
  OPEN_SPECIFIC_REVIEW_PHOTO: "OPEN_SPECIFIC_REVIEW_PHOTO",
  ONE_TIME_MODALS_FETCH: "ONE_TIME_MODALS_FETCH",
  ONE_TIME_MODALS_LOADING: "ONE_TIME_MODALS_LOADING",
  ONE_TIME_MODALS_FAILED: "ONE_TIME_MODALS_FAILED",
  REVIEW_NUDGE_MODAL_DATA: "REVIEW_NUDGE_MODAL_DATA",
  ORDER_HISTORY_SELECT_SELF_SERVICE_ORDER_GROUP: "ORDER_HISTORY_SELECT_SELF_SERVICE_ORDER_GROUP",
  ORDER_HISTORY_TOTAL_COUNT_SUCCESS: "ORDER_HISTORY_TOTAL_COUNT_SUCCESS",
  PAUSE_CTA_LOADING: "PAUSE_CTA_LOADING",
  PAYMENT_METHODS_DELETE_FAILURE: "PAYMENT_METHODS_DELETE_FAILURE",
  PAYMENT_METHODS_DELETE_REQUEST: "PAYMENT_METHODS_DELETE_REQUEST",
  PAYMENT_METHODS_DELETE_SUCCESS: "PAYMENT_METHODS_DELETE_SUCCESS",
  PAYMENT_METHODS_UPDATING: "PAYMENT_METHODS_UPDATING",
  PAYMENT_PROFILES_IS_LOADING: "PAYMENT_PROFILES_IS_LOADING",
  PAYMENT_PROFILES_LOADED: "PAYMENT_PROFILES_LOADED",
  PAYMENT_PROFILES_LOADED_ERROR: "PAYMENT_PROFILES_LOADED_ERROR",
  PDP_PAGE_VIEW: "PDP_PAGE_VIEW",
  PICK_UP_IN_STORE_ERROR: "PICK_UP_IN_STORE_ERROR",
  PICK_UP_IN_STORE_MULTI_GROUP_ERROR: "PICK_UP_IN_STORE_MULTI_GROUP_ERROR",
  PICK_UP_IN_STORE_MULTI_GROUP_SUCCESS: "PICK_UP_IN_STORE_MULTI_GROUP_SUCCESS",
  PICK_UP_IN_STORE_SUBMITTING: "PICK_UP_IN_STORE_SUBMITTING",
  PICK_UP_IN_STORE_SUCCESS: "PICK_UP_IN_STORE_SUCCESS",
  PRODUCT_DRAWER_CLOSE: "PRODUCT_DRAWER_CLOSE",
  PRODUCT_DRAWER_FETCH_CUSTOMER_REVIEWS_ERROR: "PRODUCT_DRAWER_FETCH_CUSTOMER_REVIEWS_ERROR",
  PRODUCT_DRAWER_FETCH_CUSTOMER_REVIEWS_SUCCESS: "PRODUCT_DRAWER_FETCH_CUSTOMER_REVIEWS_SUCCESS",
  PRODUCT_DRAWER_FETCH_FIT_SUMMARY_ERROR: "PRODUCT_DRAWER_FETCH_FIT_SUMMARY_ERROR",
  PRODUCT_DRAWER_FETCH_FIT_SUMMARY_SUCCESS: "PRODUCT_DRAWER_FETCH_FIT_SUMMARY_SUCCESS",
  PRODUCT_DRAWER_OPEN: "PRODUCT_DRAWER_OPEN",
  REBOOK_MULTIPLE_ORDER_GROUPS_FAILURE: "REBOOK_MULTIPLE_ORDER_GROUPS_FAILURE",
  REBOOK_MULTIPLE_ORDER_GROUPS_REVERT: "REBOOK_MULTIPLE_ORDER_GROUPS_REVERT",
  REBOOK_MULTIPLE_ORDER_GROUPS_SUCCESS: "REBOOK_MULTIPLE_ORDER_GROUPS_SUCCESS",
  REBOOK_ORDER_GROUP_CONFIRMATION: "REBOOK_ORDER_GROUP_CONFIRMATION",
  REBOOK_ORDER_GROUP_FAILURE: "REBOOK_ORDER_GROUP_FAILURE",
  REBOOK_ORDER_GROUP_REVERT: "REBOOK_ORDER_GROUP_REVERT",
  REBOOK_ORDER_GROUP_SUCCESS: "REBOOK_ORDER_GROUP_SUCCESS",
  RECEIVE_PARTIAL_PROPS: "RECEIVE_PARTIAL_PROPS",
  RECEIVE_PROPS: "RECEIVE_PROPS",
  RECEIVE_REVIEWS: "RECEIVE_REVIEWS",
  RECEIVE_REVIEWS_COVER_PHOTOS: "RECEIVE_REVIEWS_COVER_PHOTOS",
  RECOMMENDATION_CAROUSEL_SUCCESS: "RECOMMENDATION_CAROUSEL_SUCCESS",
  REFRESH_ALL_PROPS: "REFRESH_ALL_PROPS",
  REFRESH_ALL_PROPS_SUCCESS: "REFRESH_ALL_PROPS_SUCCESS",
  REFRESH_GRID_PRODUCT: "REFRESH_GRID_PRODUCT",
  REFRESH_HOLDS_FAILURE: "REFRESH_HOLDS_FAILURE",
  REFRESH_HOLDS_SUBMITTING: "REFRESH_HOLDS_SUBMITTING",
  REFRESH_HOLDS_SUCCESS: "REFRESH_HOLDS_SUCCESS",
  REMOVE_FROM_SHORTLIST_FAILURE: "REMOVE_FROM_SHORTLIST_FAILURE",
  REMOVE_FROM_SHORTLIST_SUCCESS: "REMOVE_FROM_SHORTLIST_SUCCESS",
  REMOVE_GIFT_CARD_FAILURE: "REMOVE_GIFT_CARD_FAILURE",
  REMOVE_GIFT_CARD_SUBMITTING: "REMOVE_GIFT_CARD_SUBMITTING",
  REMOVE_GIFT_CARD_SUCCESS: "REMOVE_GIFT_CARD_SUCCESS",
  REMOVE_ORDER_ITEM_FAILURE: "REMOVE_ORDER_ITEM_FAILURE",
  REMOVE_ORDER_ITEM_SUBMITTING: "REMOVE_ORDER_ITEM_SUBMITTING",
  REMOVE_ORDER_ITEM_SUCCESS: "REMOVE_ORDER_ITEM_SUCCESS",
  REMOVE_PRODUCT_RATING_ERROR: "REMOVE_PRODUCT_RATING_ERROR",
  REMOVE_PROMO_FAILURE: "REMOVE_PROMO_FAILURE",
  REMOVE_PROMO_SUBMITTING: "REMOVE_PROMO_SUBMITTING",
  REMOVE_PROMO_SUCCESS: "REMOVE_PROMO_SUCCESS",
  REMOVE_UPDATE_ITEMS: "REMOVE_UPDATE_ITEMS",
  REPLACEMENTS_ADD_REPLACEMENT: "REPLACEMENTS_ADD_REPLACEMENT",
  REPLACEMENTS_CHANGE_FILTERS: "REPLACEMENTS_CHANGE_FILTERS",
  REPLACEMENTS_CHANGE_PAGE: "REPLACEMENTS_CHANGE_PAGE",
  REPLACEMENTS_CHANGE_SORT: "REPLACEMENTS_CHANGE_SORT",
  REPLACEMENTS_REMOVE_REPLACEMENT: "REPLACEMENTS_REMOVE_REPLACEMENT",
  REPLACEMENTS_TOGGLE_OPEN_FILTERS: "REPLACEMENTS_TOGGLE_OPEN_FILTERS",
  REPLACEMENTS_TRIGGER_SECTION_ERROR: "REPLACEMENTS_TRIGGER_SECTION_ERROR",
  REPLACEMENTS_UPDATE_DISPLAY: "REPLACEMENTS_UPDATE_DISPLAY",
  REPLACEMENTS_UPDATE_SECTION: "REPLACEMENTS_UPDATE_SECTION",
  RESERVATION_FORM_FETCH_AVAILABILITY_FAILURE: "RESERVATION_FORM_FETCH_AVAILABILITY_FAILURE",
  RESERVATION_FORM_FETCH_AVAILABILITY_SUCCESS: "RESERVATION_FORM_FETCH_AVAILABILITY_SUCCESS",
  RESERVATION_BASE_ERROR: "RESERVATION_BASE_ERROR",
  RESUME_FUTURE_SKIP_MEMBERSHIP_SUCCESS: "RESUME_FUTURE_SKIP_MEMBERSHIP_SUCCESS",
  REVIEW_UPLOAD_CLEAR_PHOTOS: "REVIEW_UPLOAD_CLEAR_PHOTOS",
  REVIEW_UPLOAD_CLEAR_STATE: "REVIEW_UPLOAD_CLEAR_STATE",
  REVIEW_UPLOAD_DATA_LOADING: "REVIEW_UPLOAD_DATA_LOADING",
  REVIEW_UPLOAD_GET_PRODUCT_SUCCESS: "REVIEW_UPLOAD_GET_PRODUCT_SUCCESS",
  REVIEW_UPLOAD_GET_PENDING_REVIEW_SUCCESS: "REVIEW_UPLOAD_GET_PENDING_REVIEW_SUCCESS",
  REVIEW_UPLOAD_GET_RENTED_SIZES_SUCCESS: "REVIEW_UPLOAD_GET_RENTED_SIZES_SUCCESS",
  REVIEW_UPLOAD_SUBMIT_ERROR: "REVIEW_UPLOAD_SUBMIT_ERROR",
  REVIEW_UPLOAD_SUBMIT_LOADING: "REVIEW_UPLOAD_SUBMIT_LOADING",
  REVIEW_UPLOAD_SUBMIT_SUCCESS: "REVIEW_UPLOAD_SUBMIT_SUCCESS",
  REVIEW_UPLOAD_POPUP_SOURCE: "REVIEW_UPLOAD_POPUP_SOURCE",
  ROTATE_REVIEW_PHOTO_SUCCESS: "ROTATE_REVIEW_PHOTO_SUCCESS",
  RENT_THE_LOOK_LOADING: "RENT_THE_LOOK_LOADING",
  RENT_THE_LOOK_SUCCESS: "RENT_THE_LOOK_SUCCESS",
  SAILTHRU_OPT_IN_SUCCESS: "SAILTHRU_OPT_IN_SUCCESS",
  SAILTHRU_OPT_IN_FAILURE: "SAILTHRU_OPT_IN_FAILURE",
  SAILTHRU_OPT_IN_LOADING: "SAILTHRU_OPT_IN_LOADING",
  SAVE_GIFT_CARD_PAYMENT_METHOD_SUCCESS: "SAVE_GIFT_CARD_PAYMENT_METHOD_SUCCESS",
  SEARCH_PRODUCTS_RESULTS_RECEIVED: "SEARCH_PRODUCTS_RESULTS_RECEIVED",
  SEARCH_TYPEAHEADS_RESULTS_RECEIVED: "SEARCH_TYPEAHEADS_RESULTS_RECEIVED",
  SET_ADDRESS_ID: "SET_ADDRESS_ID",
  SET_BROWSER_DATA: "SET_BROWSER_DATA",
  SET_CSRF_PROTECTION: "SET_CSRF_PROTECTION",
  SET_FEATURE_FLAGS_DATA: "SET_FEATURE_FLAGS_DATA",
  SET_FILTERS_OPEN: "SET_FILTERS_OPEN",
  SET_FORMER_UNLIMITED: "SET_FORMER_UNLIMITED",
  SET_FORMER_UNLIMITED_LOADED: "SET_FORMER_UNLIMITED_LOADED",
  SET_HAPPINESS_SURVEY_INFO: "SET_HAPPINESS_SURVEY_INFO",
  SET_HOME_PICKUP_ADDRESS: "SET_HOME_PICKUP_ADDRESS",
  SET_HOME_PICKUP_ELIGIBILITY: "SET_HOME_PICKUP_ELIGIBILITY",
  SET_HOME_PICKUP_ELIGIBLE_ORDERS: "SET_HOME_PICKUP_ELIGIBLE_ORDERS",
  SET_HOME_PICKUP_POSTAL_CODES: "SET_HOME_PICKUP_POSTAL_CODES",
  SET_HOME_PICKUP_POSTAL_CODE_GROUPS: "SET_HOME_PICKUP_POSTAL_CODE_GROUPS",
  SET_IS_HOME_PICKUP_SCHEDULER_LOADING: "SET_IS_HOME_PICKUP_SCHEDULER_LOADING",
  SET_ITEM_KEPT: "SET_ITEM_KEPT",
  SET_ITEM_PURCHASED: "SET_ITEM_PURCHASED",
  SET_NEW_CUSTOMER_OFFER_TEXT: "SET_NEW_CUSTOMER_OFFER_TEXT",
  SET_PERSISTED_FILTERS: "SET_PERSISTED_FILTERS",
  SET_PAGE_NAME: "SET_PAGE_NAME",
  SET_PAGE_TYPE: "SET_PAGE_TYPE",
  SET_PRODUCT: "SET_PRODUCT",
  SET_SNACK_BAR: "SET_SNACK_BAR",
  SET_UPCOMING_HOME_PICKUP_DATE: "SET_UPCOMING_HOME_PICKUP_DATE",
  SET_USER_STYLE_PREFERENCES: "SET_USER_STYLE_PREFERENCES",
  SET_UX_USER_DATA: "SET_UX_USER_DATA",
  SCHEDULE_HOME_PICKUP_ERROR: "SCHEDULE_HOME_PICKUP_ERROR",
  SCHEDULE_HOME_PICKUP_SUCCESS: "SCHEDULE_HOME_PICKUP_SUCCESS",
  SHIPPING_STEP_FAILURE: "SHIPPING_STEP_FAILURE",
  SHIPPING_STEP_INIT_VALUES: "SHIPPING_STEP_INIT_VALUES",
  SHIPPING_STEP_SET_AUTOCOMPLETE_VALUES: "SHIPPING_STEP_SET_AUTOCOMPLETE_VALUES",
  SHIPPING_STEP_SET_ISVALID: "SHIPPING_STEP_SET_ISVALID",
  SHIPPING_STEP_SUBMITTING: "SHIPPING_STEP_SUBMITTING",
  SHIPPING_STEP_SUBMITTING_RESET: "SHIPPING_STEP_SUBMITTING_RESET",
  SHIPPING_STEP_SUCCESS: "SHIPPING_STEP_SUCCESS",
  SHIPPING_STEP_UPDATE_ELEMENT: "SHIPPING_STEP_UPDATE_ELEMENT",
  SHIPPING_STEP_UPDATE_SHIPPING_TYPE: "SHIPPING_STEP_UPDATE_SHIPPING_TYPE",
  SHIPPING_STEP_VALIDATE_BEFORE_SUBMIT: "SHIPPING_STEP_VALIDATE_BEFORE_SUBMIT",
  SHIPPING_STEP_VALIDATION_CONFIRMATION_REQUIRED: "SHIPPING_STEP_VALIDATION_CONFIRMATION_REQUIRED",
  SHIPPING_STEP_VALIDATION_SUBMITTED: "SHIPPING_STEP_VALIDATION_SUBMITTED",
  SHOPPABLE_IMAGES: "SHOPPABLE_IMAGES",
  SHOW_GRID_EDUCATION_TOOLTIP: "SHOW_GRID_EDUCATION_TOOLTIP",
  SHOW_MODAL: "SHOW_MODAL",
  SHOW_ON_RACK_AVAILABILITY_TOOLTIP: "SHOW_ON_RACK_AVAILABILITY_TOOLTIP",
  SHOW_SIZE_FILTER_PROMPT: "SHOW_SIZE_FILTER_PROMPT",
  SIMILAR_STYLES_LOADING: "SIMILAR_STYLES_LOADING",
  SIMILAR_STYLES_SUCCESS: "SIMILAR_STYLES_SUCCESS",
  SM_BAG_ERROR: "SM_BAG_ERROR",
  SM_BAG_IS_LOADING: "SM_BAG_IS_LOADING",
  SM_BAG_SUCCESS: "SM_BAG_SUCCESS",
  SM_BAG_REMOVE_UNAVAILABLE_ITEM: "SM_BAG_REMOVE_UNAVAILABLE_ITEM",
  SMART_CHECKOUT_BANNER_FAILURE: "SMART_CHECKOUT_BANNER_FAILURE",
  SMART_CHECKOUT_BANNER_SUCCESS: "SMART_CHECKOUT_BANNER_SUCCESS",
  SMART_MEMBERSHIP_HOMEPAGE_HEADER_FAILURE: "SMART_MEMBERSHIP_HOMEPAGE_HEADER_FAILURE",
  SMART_MEMBERSHIP_HOMEPAGE_HEADER_SUCCESS: "SMART_MEMBERSHIP_HOMEPAGE_HEADER_SUCCESS",
  SMART_NAV_CONTENT_SUCCESS: "SMART_NAV_CONTENT_SUCCESS",
  SMART_NAV_MOBILE_CONTENT_SUCCESS: "SMART_NAV_MOBILE_CONTENT_SUCCESS",
  SMART_PDP_CONTENT_FAILURE: "SMART_PDP_CONTENT_FAILURE",
  SMART_PDP_CONTENT_SUCCESS: "SMART_PDP_CONTENT_SUCCESS",
  SMART_REFERRALS_SALE_GTM_MODAL_SUCCESS: "SMART_REFERRALS_SALE_GTM_MODAL_SUCCESS",
  SMART_RENT_MY_STYLE_QUIZ_SUCCESS: "SMART_RENT_MY_STYLE_QUIZ_SUCCESS",
  SMART_SKINNY_BANNER_CONTENT: "SMART_SKINNY_BANNER_CONTENT",
  SMART_SKINNY_BANNER_CONTENT_SUCCESS: "SMART_SKINNY_BANNER_CONTENT_SUCCESS",
  SMART_TOP_RIGHT_CONTENT: "SMART_TOP_RIGHT_CONTENT",
  SMART_TOP_RIGHT_CONTENT_SUCCESS: "SMART_TOP_RIGHT_CONTENT_SUCCESS",
  GET_SMART_REFERRALS_REMINDER_MODAL: "GET_SMART_REFERRALS_REMINDER_MODAL",
  GET_SMART_REFERRALS_REMINDER_MODAL_SUCCESS: "GET_SMART_REFERRALS_REMINDER_MODAL_SUCCESS",
  SUBMIT_FILTERS: "SUBMIT_FILTERS",
  SUBMIT_FORM: "SUBMIT_FORM",
  SUBMIT_QUIZ_DATA_SUCCESS: "SUBMIT_QUIZ_DATA_SUCCESS",
  SUBMIT_REVIEW_PHOTO_SUCCESS: "SUBMIT_REVIEW_PHOTO_SUCCESS",
  SUBMITTING_RESERVATION: "SUBMITTING_RESERVATION",
  SUBSCRIPTION_NOTIFICATION_SUCCESS: "SUBSCRIPTION_NOTIFICATION_SUCCESS",
  SUPPRESS_SKINNY_BANNER: "SUPPRESS_SKINNY_BANNER",
  SWAP_COUNTER_TOOLTIP_DISMISSED: "SWAP_COUNTER_TOOLTIP_DISMISSED",
  SWAP_COUNTER_TOOLTIP_TRIGGERED: "SWAP_COUNTER_TOOLTIP_TRIGGERED",
  TOGGLE_MOBILE_NAV: "TOGGLE_MOBILE_NAV",
  TOGGLE_MOBILE_NAV_ACCOUNT_MENU: "TOGGLE_MOBILE_NAV_ACCOUNT_MENU",
  TOGGLE_OPEN_FILTERS: "TOGGLE_OPEN_FILTERS",
  TOGGLE_SEARCH_EXPANDED: "TOGGLE_SEARCH_EXPANDED",
  TOGGLE_SNEAKY_HEADER_VISIBLE: "TOGGLE_SNEAKY_HEADER_VISIBLE",
  TOOLTIP_SETTINGS: "TOOLTIP_SETTINGS",
  TRIGGER_ERROR: "TRIGGER_ERROR",
  TRIGGER_GENERIC_MODAL: "TRIGGER_GENERIC_MODAL",
  UNSET_ITEM_KEPT: "UNSET_ITEM_KEPT",
  UPCOMING_ORDER_AVAILABLE_SIZES_FOR_SKUS_RESET: "UPCOMING_ORDER_AVAILABLE_SIZES_FOR_SKUS_RESET",
  UPCOMING_ORDER_AVAILABLE_SIZES_FOR_SKUS_SUCCESS: "UPCOMING_ORDER_AVAILABLE_SIZES_FOR_SKUS_SUCCESS",
  UPCOMING_ORDER_CHANGE_SIZE_BOOKING_IDS_TO_REMOVE_SUCCESS: "UPCOMING_ORDER_CHANGE_SIZE_BOOKING_IDS_TO_REMOVE_SUCCESS",
  UPCOMING_ORDER_CHANGE_SIZE_NEW_HOLD_IDS_SUCCESS: "UPCOMING_ORDER_CHANGE_SIZE_NEW_HOLD_IDS_SUCCESS",
  UPCOMING_ORDER_CHANGE_SIZE_RESET: "UPCOMING_ORDER_CHANGE_SIZE_RESET",
  UPCOMING_ORDER_GET_CANCELLATION_FEE_SUCCESS: "UPCOMING_ORDER_GET_CANCELLATION_FEE_SUCCESS",
  UPCOMING_ORDER_MODIFICATION_FAILURE: "UPCOMING_ORDER_MODIFICATION_FAILURE",
  UPCOMING_ORDER_MODIFICATION_LOADING: "UPCOMING_ORDER_MODIFICATION_LOADING",
  UPCOMING_ORDER_MODIFICATION_PRICE_CHANGE: "UPCOMING_ORDER_MODIFICATION_PRICE_CHANGE",
  UPCOMING_ORDER_MODIFICATION_SUCCESS: "UPCOMING_ORDER_MODIFICATION_SUCCESS",
  UPDATE_AUTH_MODAL: "UPDATE_AUTH_MODAL",
  UPDATE_CURRENT_PAGE: "UPDATE_CURRENT_PAGE",
  UPDATE_EVENT_TYPE_BEGIN: "UPDATE_EVENT_TYPE_BEGIN",
  UPDATE_EVENT_TYPE_SUCCESS: "UPDATE_EVENT_TYPE_SUCCESS",
  UPDATE_MEMBERSHIP_LENS: "UPDATE_MEMBERSHIP_LENS",
  UPDATE_MEMBERSHIP_STATE: "UPDATE_MEMBERSHIP_STATE",
  UPDATE_MEMBERSHIP_STATE_FAIL: "UPDATE_MEMBERSHIP_STATE_FAIL",
  UPDATE_MULTIPLE_ORDER_GROUPS_FAILURE: "UPDATE_MULTIPLE_ORDER_GROUPS_FAILURE",
  UPDATE_MULTIPLE_ORDER_GROUPS_PARTIAL_SUCCESS: "UPDATE_MULTIPLE_ORDER_GROUPS_PARTIAL_SUCCESS",
  UPDATE_MULTIPLE_ORDER_GROUPS_SUCCESS: "UPDATE_MULTIPLE_ORDER_GROUPS_SUCCESS",
  UPDATE_ORDER_GROUP_FAILURE: "UPDATE_ORDER_GROUP_FAILURE",
  UPDATE_ORDER_GROUP_SUBMITTING: "UPDATE_ORDER_GROUP_SUBMITTING",
  UPDATE_ORDER_GROUP_SUCCESS: "UPDATE_ORDER_GROUP_SUCCESS",
  UPDATE_ORDER_PAYMENT_FAILURE: "UPDATE_ORDER_PAYMENT_FAILURE",
  UPDATE_ORDER_PAYMENT_SUBMITTING: "UPDATE_ORDER_PAYMENT_SUBMITTING",
  UPDATE_ORDER_PAYMENT_SUCCESS: "UPDATE_ORDER_PAYMENT_SUCCESS",
  UPDATE_RESERVATION: "UPDATE_RESERVATION",
  UPDATE_RESERVATION_ERRORS: "UPDATE_RESERVATION_ERRORS",
  UPDATE_REVIEW_PHOTO_FAILURE: "UPDATE_REVIEW_PHOTO_FAILURE",
  UPDATE_SHORTLIST_BEGIN: "UPDATE_SHORTLIST_BEGIN",
  UPDATE_SHORTLIST_SUCCESS: "UPDATE_SHORTLIST_SUCCESS",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_DATA: "UPDATE_USER_DATA", // not to be confused with UPDATE_USER (which sets a user's profile)
  UPDATE_USER_DONE: "UPDATE_USER_DONE",
  UPDATE_USER_FAIL: "UPDATE_USER_FAIL",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  UPDATE_USER_PROFILE_DONE: "UPDATE_USER_PROFILE_DONE",
  UPDATE_USER_PROFILE_FAIL: "UPDATE_USER_PROFILE_FAIL",
  UPDATE_WORKING_REVIEWS_FILTERS: "UPDATE_WORKING_REVIEWS_FILTERS",
  USER_HISTORY_STATS: "USER_HISTORY_STATS",
  USER_HISTORY_STATS_LOADING: "USER_HISTORY_STATS_LOADING",
  USER_HISTORY_STATS_FAILURE: "USER_HISTORY_STATS_FAILURE",
  USER_REVIEW_SUMMARY: "USER_REVIEW_SUMMARY",
  WINDOW_RESIZE: "WINDOW_RESIZE",
  WRITE_HOLD_PREFERENCES_SUCCESS: "WRITE_HOLD_PREFERENCES_SUCCESS",
  YOU_MAY_ALSO_LIKE_SUCCESS: "YOU_MAY_ALSO_LIKE_SUCCESS",
  YOU_MAY_ALSO_LIKE_LOADING: "YOU_MAY_ALSO_LIKE_LOADING",
};

export default ActionTypes;
