var carouselTypes = {
  SIMILAR_ITEMS: "SIMILAR_ITEMS",
  OUTFITS: "OUTFITS",
  VISUALLY_SIMILAR_TO: "VISUALLY_SIMILAR_TO",
  RECENTLY_VIEWED: "RECENTLY_VIEWED",
  COLLECTION: "COLLECTION",
  RECOMMENDATION: "RECOMMENDATION",
  CLEARANCE: "CLEARANCE",
};

export default carouselTypes;

export const { SIMILAR_ITEMS, OUTFITS, VISUALLY_SIMILAR_TO, RECENTLY_VIEWED } = carouselTypes;
